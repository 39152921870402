@import "~bootstrap/scss/functions";

$blue: #0092e7;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #e88709;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #00a3cc;
$lipstick: #ce1771;
$tealist: #27c0cd;
$ice: #f3f8f8;
$lightblue: #b9e5ff;
$nobg: rgba(0, 0, 0, 0);

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #191919;
$black: #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);

$primary: $blue;
$light-secondary: $lightblue;
$secondary: $tealist;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$noBg: $nobg;

/* make the customizations */
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

@import "~bootstrap/scss/bootstrap.scss";
