// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

  .testimonial-img {
    width: 200px;
  }

  .img-choose-us{
    width: 80%;
    height: auto;
  }

  .img-navbar {
    width: 80%;
  }

  .nav-menu {
    margin: 0px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  div#rms-nav .navbar-nav a {
    margin-left: 0;
  }
  .overview.card {
    background-size: 12rem;
    background-position: top right;
  }
  .collaborate.card {
    background-size: 12rem;
    background-position: top right;
  }
  span.title-sm {
    margin: 5px 10px;
    font-size: 10px;
  }
  .video-text {
    font-size: 32px;
  }
  .testimonial-img {
    width: 150px;
  }
  .img-choose-us{
    width: 100%;
    height: auto;
  }
  .identity-director {
    bottom: 4%;
  }

  .col-main {
    padding-left: 10%;
    padding-right: 10%;
  }

  .organization-card {
    width: 100%;
  }

  .timeline > div > .timeline-panel {
    float: right;
  }

  .timeline > div > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;  
  }

  .timeline:before {
    left: 0%;
    margin-left: -1.5px;
  }

  .timeline > div > .timeline-badge {
    left: 0%;
    margin-left: -10px;
  }
  
  .timeline > div > .timeline-panel {
    width: 90%;
  }

  .navbar-landing {
    padding: 0px 5px; 
  }

  .img-navbar {
    width: 90%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .overview.card {
    background-size: 7.5rem;
    background-position: top right;
  }
  .collaborate.card {
    background-size: 7.5rem;
    background-position: top right;
  }
  .list-cta li {
    margin: 15px;
  }

  img.logo-partners {
    width: 75px;
  }

  img.logo-strategic {
    width: 75px;
  }

  .img-choose-us{
    width: 80%;
    height: auto;
    margin-top: 15px ;
  }

  .identity-director {
    bottom: 4%;
  }

  .img-portfolio {
    height: 180px;
  }

  .row-organization {
    margin-left: 0%;
    margin-right: 0%;
  }

  .title-content-director {
    font-size: 18px;
  }

  .title-detail-director {
    font-size: 13px;
  }

  .col-main {
    padding-left: 5%;
    padding-right: 5%;
  }

  .img-timeline {
    height: 200px;
    object-fit: cover;
  }

  .organization-card {
    width: 100%;
  }

  .timeline > div > .timeline-panel {
    float: right;
  }

  .timeline > div > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;  
  }

  .timeline:before {
    left: 0%;
    margin-left: -1.5px;
  }

  .timeline > div > .timeline-badge {
    left: 0%;
    margin-left: -10px;
  }

  .timeline > div > .timeline-panel {
    width: 90%;
  }

  .btn-header-organization {
    margin: 0px;
  }

  .arrow-branch {
    top: 30%;
  }
  
  .img-navbar {
    width: 70%;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .testimonial-img {
    width: 200px;
  }

  .img-choose-us{
    width: 80%;
    height: auto;
  }

  .btn-header-organization {
    margin: 0px;
  }

  .arrow-branch {
    top: 30%;
  }


}

