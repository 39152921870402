@font-face {
  font-family: Circular800;
  src: url("./CircularStd-Black.otf");
}
@font-face {
  font-family: Circular700;
  src: url("./CircularStd-Bold.otf");
}
@font-face {
  font-family: Circular600;
  src: url("./CircularStd-Medium.otf");
}
@font-face {
  font-family: Circular;
  src: url("./CircularStd-Book.otf");
}
@font-face {
  font-family: Circular400;
  src: url("./CircularStd-Medium.otf");
}
